.callout {
  @extend .alert;
  padding: 0px;
  border-left: none !important;
  border-radius: 4px;

  header {
    color: $white;
    line-height: 24px;
    font-weight: 500;
    padding: 8px 16px;
    margin: 0;
    text-transform: uppercase;
    border-radius: 4px 4px 0 0;
  }

  p {
    padding: 16px;
    margin: 0px;
    font-size: 14px;
  }

  &.is-critical {
    border-color: $brightred;
    background: rgba($brightred, 0.05);

    header {
      background: $brightred;
    }
  }

  &.is-important {
    border-color: $orange;
    background: rgba($orange, 0.05);

    header {
      background: $amber-700;
    }
  }

  &.is-helpful {
    border-color: $purple;
    background: rgba($purple, 0.05);

    header {
      background: $purple;
    }
  }
}
