hr {
    border: none;
    background: #36203A;
    height: 1px;
}

.hr-margin {
    display: block;
    height: 1px;
    border: 0;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 0;
}