/* SELECT MENU */

.form-select-menu {
  position: relative;
}

.form-select-button {
  background: $white;
  box-shadow: 0 2px 2px rgba($black, 0.24), 0 0 2px rgba($black, 0.12);
  box-sizing: border-box;
  border: 1px solid $white;
  color: $purple-grey-600;
  font-size: 12px;
  font-weight: 400;
  height: 32px;
  line-height: 32px;
  outline: none;
  padding: 0 16px;
  text-align: left;
  width: 100%;
  cursor: pointer;

  strong {
    font-weight: 600;
    margin-right: 8px;
    text-transform: uppercase;
  }

  &:focus {
    border: 1px solid $purple-400;
    box-shadow: 0 2px 2px rgba($purple-400, 0.24), 0 0 2px rgba($purple-400, 0.12);
  }
}

.form-select-dropdown {
  background: $white;
  box-shadow: 0 16px 16px rgba($black, 0.24), 0 0 16px rgba($black, 0.12);
  border-radius: 4px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: $layer-2;

  li {
    cursor: pointer;
    font-size: 14px;
    line-height: 32px;
    margin: 0;
    padding: 0 16px 0 40px;
    position: relative;
    transition: all .2s;

    &:hover {
      background: $purple-grey-50;
      color: $purple-500;
    }

    &.selected {
      background-color: $purple-grey-100;
    }

    .symbol {
      left: 16px;
      position: absolute;
      top: 8px;
      z-index: $layer-5;
    }
  }
}
