
aio-shell.mode-archive {

  .mat-toolbar.mat-primary, footer {
    background: linear-gradient(145deg,#263238,#78909C);
  }

  .vertical-menu-item {
    &.selected, &:hover {
      color: #263238;
    }
  }

  .toc-inner ul.toc-list li.active a {
    color: #263238;

    &:before {
      background-color: #263238;
    }
  }

  .toc-inner ul.toc-list li:hover a {
    color: #263238;
  }
}

aio-shell.mode-next {

 footer {
    background: linear-gradient(145deg,#84438a,#84438a);
  }

  .vertical-menu-item {
    &.selected, &:hover {
      color: #84438a;
    }
  }

  .toc-inner ul.toc-list li.active a {
    color: #84438a;

    &:before {
      background-color: #84438a;
    }
  }

  .toc-inner ul.toc-list li:hover a {
    color: #84438a;
  }
}
